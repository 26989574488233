import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem, ModelList, OrganizationReportRollupModel } from 'app/models';
import { LOAD_METHOD } from 'app/models/ModelContainer';
import OrganizationReportRollupDivisionModel from 'app/models/OrganizationReportRollupDivisionModel';
import OrganizationReportRollupTeamGroupModel from 'app/models/OrganizationReportRollupTeamGroupModel';

import Store from './Store';

const enum RollupTypes {
  OrganizationReportRollup = 'organization_report_rollup',
  BenchmarksReportRollup = 'benchmarks_report_rollup',
}

export class OrganizationReportRollupStore extends Store<OrganizationReportRollupModel> {
  @observable filters = new ModelList<OrganizationReportRollupDivisionModel>(
    OrganizationReportRollupDivisionModel
  );
  @observable rollup = new ModelItem<OrganizationReportRollupModel>(OrganizationReportRollupModel);
  @observable benchmarkRollup = new ModelItem<OrganizationReportRollupModel>(
    OrganizationReportRollupModel
  );

  constructor() {
    super();
    OrganizationReportRollupDivisionModel._store = this;
    OrganizationReportRollupTeamGroupModel._store = this;
    OrganizationReportRollupModel._store = this;
  }

  loadFilters(orgId: number): Promise<void> {
    const url = ServerRouteHelper.api.organizations.reportRollup.filters(orgId);
    return this.filters.load(url);
  }

  loadRollup(
    orgId: number,
    exerciseTypeId: number,
    teamGroupIds: number[],
    params?: Record<string, any>
  ): Promise<void> {
    const url = ServerRouteHelper.api.organizations.reportRollup.rollup(
      orgId,
      exerciseTypeId,
      params
    );

    const id = this.getRollupId(
      teamGroupIds,
      exerciseTypeId,
      RollupTypes.OrganizationReportRollup,
      params
    );

    const existingRollupModel = OrganizationReportRollupModel.get(id);
    if (existingRollupModel) {
      this.rollup.setItem(existingRollupModel);
      return;
    }

    return this.rollup.load(
      url,
      { teamGroupIds, rollupType: RollupTypes.OrganizationReportRollup },
      { method: LOAD_METHOD.POST }
    );
  }

  loadBenchmarkRollup(
    orgId: number,
    exerciseTypeId: number,
    teamGroupIds: number[]
  ): Promise<void> {
    const url = ServerRouteHelper.api.organizations.reportRollup.rollup(orgId, exerciseTypeId);

    const id = this.getRollupId(teamGroupIds, exerciseTypeId, RollupTypes.BenchmarksReportRollup);

    const existingBenchMarkRollupModel = OrganizationReportRollupModel.get(id);
    if (existingBenchMarkRollupModel) {
      this.benchmarkRollup.setItem(existingBenchMarkRollupModel);
      return;
    }

    return this.benchmarkRollup.load(
      url,
      { teamGroupIds, rollupType: RollupTypes.BenchmarksReportRollup },
      { method: LOAD_METHOD.POST }
    );
  }

  getRollupId(
    teamGroupIds: number[],
    exerciseTypeId: number,
    rollupType: string,
    params?: Record<string, any>
  ): string {
    const encodedParams = new URLSearchParams(params ?? {}).toString();

    return `${teamGroupIds.join('-')}-${exerciseTypeId}-${rollupType}-${encodedParams}`;
  }
}

export default OrganizationReportRollupStore;
