import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import OrgMetricsStore from 'app/stores/OrgMetricsStore';

interface Metric {
  name: string;
  value: number;
}

export class OrgMetricsModel extends Model {
  public static readonly _store: OrgMetricsStore;

  @observable public id: string;
  @observable public metrics: Metric[];

  static fromJson(json: ModelJson): OrgMetricsModel {
    return this._fromJson(json) as OrgMetricsModel;
  }

  static getOrNew(id): OrgMetricsModel {
    return this._getOrNew(id) as OrgMetricsModel;
  }

  static get(id): OrgMetricsModel {
    return this._get(id) as OrgMetricsModel;
  }
}

export default OrgMetricsModel;
