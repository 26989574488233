import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem } from 'app/models';
import EngagementAnalyticsModel from 'app/models/EngagementAnalyticsModel';

import Store from './Store';

export class EngagementAnalyticsStore extends Store<EngagementAnalyticsModel> {
  @observable engagementAnalytics = new ModelItem<EngagementAnalyticsModel>(
    EngagementAnalyticsModel
  );

  constructor() {
    super();
    EngagementAnalyticsModel._store = this;
  }

  @action
  async loadByOrgId(orgId: number, params: Record<string, any>): Promise<void> {
    const url = ServerRouteHelper.api.organizations.contractAnalytics(orgId);
    this.engagementAnalytics.load(url, { filters: params }, { forceRefresh: true });
  }
}

export default EngagementAnalyticsStore;
