import { createPath } from 'history';

export const buildURLParams = (params: { [key: string]: string | number } = {}) =>
  Object.keys(params || {})
    .filter((key) => params[key]?.toString().length > 0)
    .map((key) => params[key] !== undefined && `${key}=${params[key]}`)
    .filter((pair) => pair)
    .join('&');

export const buildURL = (...args) => {
  const lastArg = args[args.length - 1];
  const lastArgIsObject = typeof lastArg === 'object';

  const params = lastArgIsObject && buildURLParams(lastArg);
  const segments = lastArgIsObject ? args.slice(0, -1) : args;

  const path = segments.filter((arg) => arg).join('/');

  let pathPrefix = '/';

  if (path.startsWith('http') || path.startsWith('/')) {
    pathPrefix = '';
  }

  if (params) {
    return [pathPrefix + path, params].join('?');
  }

  return pathPrefix + path;
};

export const buildUTMQueryString = (
  utm_campaign: string,
  utm_medium = 'email',
  utm_source = 'app'
) =>
  buildURLParams({
    utm_source,
    utm_medium,
    utm_campaign,
  });

export const ensureRelativeURL = (url: string): string => url.replace(window.location.origin, '');

/**
 * Get the current "full but relative" URL
 * i.e. including query params and hash anchor, but relative not absolute
 *
 * If passing to a `back_to` query param, you should use `getCurrentRelativePathEncoded()`
 */
export const getCurrentRelativePath = (): string => {
  return createPath(window.location);
};

/**
 * Get the current "full but relative" URL, encoded for setting as a URI component (i.e. `back_to` query param).
 * "Full but relative" means including query params and hash anchor, but relative not absolute
 */
export const getCurrentRelativePathEncoded = (): string => {
  return encodeURIComponent(getCurrentRelativePath());
};
