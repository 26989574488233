import { action, computed, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import EntityStateModel, { EntityStateAssociationType } from 'app/models/EntityStateModel';
import { ModelList } from 'app/models/ModelList';
import Store from 'app/stores/Store';

export class EntityStateStore extends Store<EntityStateModel> {
  @observable public exerciseEntityStates = new ModelList<EntityStateModel>(EntityStateModel);
  @observable public teamEntityStates = new ModelList<EntityStateModel>(EntityStateModel);
  @observable public memberEntityStates = new ModelList<EntityStateModel>(EntityStateModel);

  // Story time...
  //
  // Unlike other stores, EntityStateStore's *EntityStates observables can load different urls, most of the time only changing on query params.
  // This is because it can aggregate results from different entity state params but same path. This however trips the ModelContainer's built-in
  // caching in that it sees these query param changes as completely different requests thereby causing the request to trigger even though the/
  // same url has been previously requested already, because it can only remember the last url.
  //
  // To work around this, we keep a map of urls that has been loaded and check against it so
  // we don't request the same thing again unless the method supports forcing request in which case it trumps this caching.
  @observable loadedEntityStateUrls: Record<string, boolean> = {};
  @action setLoadedEntityStateUrl = (url: string, state = true): void => {
    this.loadedEntityStateUrls[url] = state;
  };

  @observable allHasLoaded: boolean;
  @action setAllHasLoaded = (state: boolean): void => {
    this.allHasLoaded = state;
  };

  constructor() {
    super();
    EntityStateModel._store = this;
  }

  @action
  public async loadExerciseOwnedEntityStates(
    entityType: EntityStateAssociationType,
    entityID?: number,
    filter?: { [key: string]: any },
    redirectIfUnauthorized?: boolean
  ) {
    let query = {
      entity_type: entityType,
    };

    if (entityID) {
      query['entity_id'] = entityID;
    }

    if (filter) {
      query = { ...query, ...filter };
    }

    const url = ServerRouteHelper.api.exercises.entityStates(entityID, query);

    if (this.isEntityStateLoaded(url.toString())) {
      return;
    }

    await this.exerciseEntityStates.load(url, undefined, {
      redirectIfUnauthorized: !!redirectIfUnauthorized,
      append: true,
      skipCancel: true,
    });

    this.setLoadedEntityStateUrl(url.toString());
  }

  @action
  public async loadExerciseOwnedEntityStatesMultiple(
    entityType: EntityStateAssociationType,
    entityIDs?: number[],
    filter?: { [key: string]: any },
    redirectIfUnauthorized?: boolean,
    forceRefresh?: boolean
  ) {
    let query = {
      entity_type: entityType,
    };

    if (entityIDs) {
      query['entity_ids'] = entityIDs.join(',');
    }

    if (filter) {
      query = { ...query, ...filter };
    }

    const url = ServerRouteHelper.api.exercises.ownedEntityStates(query);

    if (this.isEntityStateLoaded(url.toString()) && !forceRefresh) {
      return;
    }

    await this.exerciseEntityStates.load(url, undefined, {
      redirectIfUnauthorized: !!redirectIfUnauthorized,
      forceRefresh: !!forceRefresh,
      append: true,
      skipCancel: true,
    });

    this.setLoadedEntityStateUrl(url.toString());
  }

  @action
  public async loadTeamOwnedEntityStates(
    entityID?: number,
    filter?: { [key: string]: any },
    redirectIfUnauthorized?: boolean,
    forceRefresh = false
  ): Promise<void> {
    let query = {
      entity_type: EntityStateAssociationType.Team,
    };

    if (entityID) {
      query['entity_id'] = entityID;
    }

    if (filter) {
      query = { ...query, ...filter };
    }

    const url = ServerRouteHelper.api.teams.entityStates(entityID, query);

    if (this.isEntityStateLoaded(url.toString()) && !forceRefresh) {
      return;
    }

    await this.teamEntityStates.load(url, undefined, {
      redirectIfUnauthorized: !!redirectIfUnauthorized,
      append: true,
      skipCancel: true,
      forceRefresh,
    });

    this.setLoadedEntityStateUrl(url.toString());
  }

  @action
  public async loadMemberOwnedEntityStates(
    entityType: EntityStateAssociationType,
    entityID?: number,
    filter?: { [key: string]: any },
    sharedLinkToken?: string
  ) {
    let url = ServerRouteHelper.api.members.entityStates();

    let query = {
      entity_type: entityType,
    };

    if (entityID) {
      query['entity_id'] = entityID;
    }

    if (filter) {
      query = { ...query, ...filter };
    }

    if (sharedLinkToken) {
      query['shared_link_token'] = sharedLinkToken;
    }

    url = url.withParams(query);

    // If the URL has been loaded, don't load it again.
    if (this.isEntityStateLoaded(url.toString())) {
      return;
    }

    await this.memberEntityStates.load(url, undefined, {
      append: true,
      skipCancel: true,
    });

    this.setLoadedEntityStateUrl(url.toString());
  }

  @action
  public async loadCurrentMemberOwnedEntityStates(
    filter?: { [key: string]: any },
    sharedLinkToken?: string
  ) {
    await this.loadMemberOwnedEntityStates(
      EntityStateAssociationType.CurrentMember,
      null,
      filter,
      sharedLinkToken
    );
  }

  @action
  public async upsertMemberOwnedEntityState(
    entityType: EntityStateAssociationType,
    entityID: number | null,
    data: Partial<EntityStateModel> & { member_id?: number },
    sharedLinkToken?: string
  ) {
    const url = ServerRouteHelper.api.members.entityStates();

    data.entity_type = entityType;

    if (entityID) {
      data.entity_id = entityID;
    }

    if (sharedLinkToken) {
      const query = { shared_link_token: sharedLinkToken };
      url.withParams(query);
    }

    const config = {
      url,
      data,
    };

    const response = await this.apiService.newPost(config);

    if (!response) {
      return;
    }

    this.memberEntityStates.appendItem(response.data);
    return EntityStateModel.fromJson(response.data);
  }

  @action
  public async upsertCurrentMemberOwnedEntityState(
    data: Partial<EntityStateModel> & { member_id?: number },
    sharedLinkToken?: string
  ) {
    return this.upsertMemberOwnedEntityState(
      EntityStateAssociationType.CurrentMember,
      null,
      data,
      sharedLinkToken
    );
  }

  @computed
  get loadedMemberEntityStateTypes(): string[] {
    return this.memberEntityStates.items.map(({ type }) => type);
  }

  public findExerciseEntityStateWithKey = (key: string) =>
    this.exerciseEntityStates.items.find((item) => item.type === key);

  public findTeamEntityStateWithKey = (teamId: number, key: string) =>
    this.teamEntityStates.items.find((item) => {
      return (
        item.type === key &&
        item.entity_id === teamId &&
        item.entity_type === EntityStateAssociationType.Team
      );
    });

  public findMemberEntityStateWithKey = (key: string) =>
    this.memberEntityStates.items.find((item) => item.type === key);

  public findMemberTeamEntityState = (teamId: number, key: string) =>
    this.memberEntityStates.items.find((item) => item.entity_id === teamId && item.type === key);

  isEntityStateLoaded = (url: string): boolean => {
    return this.loadedEntityStateUrls[url] ?? false;
  };
}

export default EntityStateStore;
