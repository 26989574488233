import { assign, isString } from 'lodash';
import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { OrganizationContentStore } from 'app/stores';

/**
 * Content type keys enum map
 */
export enum OrganizationContent {
  OnboardingQuestions = 'onboarding-questions',
  OrganizationQuotes = 'organization-quotes',
  WhatOthersSay = 'what-others-say',
  ReturningToOfficePromo = 'returning-to-office-promo',
  TeamSummaryCards = 'team-summary-cards',
  PerspectiveInviteEmail = 'perspective-invite-email',
  WelcomeMessageCard = 'welcome-message-card',
  PersonalHabitsPromo = 'personal-habits-promo',
  TeamAlignPromo = 'team-align-promo',
  AvailableTools = 'available-tools',
  AlignTestimonials = 'align-testimonials',
  TeamPerspectiveTestimonials = 'team-perspective-testimonials',
  My360ReportReflectCardsContent = '360-reflect-cards',
  TeamHabitsTestimonials = 'team-habits-testimonial',
  Reflect360Testimonials = 'reflect-360-testimonial',
  CustomOnboardingMarqueeCards = 'custom-onboarding-marquee-card',
  BillingHelp = 'billing-help',
  SubscriptionVendorDetails = 'subscription-vendor-details',
}

export enum TeamSummaryEmptyCardType {
  Align = 'team_align_card',
  Perspective = 'team_perspective_card',
  TeamHabit = 'team_habit_card',
  PersonalHabit = 'personal_habit_card', // Remove this when we convert PersonalHabitEmptyStateCard to team in sc-91016
}

export enum OnboardingQuestionHandlerType {
  QuestionCard = 'question_card',
  WelcomeRoleQuestionCard = 'welcome_role_question_card',
  TeamSelectorQuestionCard = 'team_selector_question_card',
}

export enum OrganizationContentType {
  Text = 'text',
  Array = 'array',
}

export interface TeamSummaryCardTip {
  message: string;
  avatar: string;
  conditions: boolean;
}

/**
 * key: onboarding-questions
 */
export class OrganizationContentQuestion {
  @observable id: string;
  @observable question: string;
  @observable multi_select: boolean;
  @observable question_subtitle: string;
  @observable options: { id: string; value: string; heading?: string }[];
  @observable is_default: boolean;
  @observable welcome_note: string;
  @observable handler?: OnboardingQuestionHandlerType;

  constructor(props) {
    assign(this, props);
  }
}

export interface CustomOnboardingMarqueeCardsCTA {
  text: string;
  url?: string;
  external: boolean;
}

export class CustomOnboardingMarqueeCard {
  @observable id: string;
  @observable title: string;
  @observable text: string;
  @observable image: string;
  @observable cta: CustomOnboardingMarqueeCardsCTA;
}

/**
 * key: what-others-say
 */
export class OrganizationContentWhatOthersSay {
  @observable quote: string;
  @observable title: string;
  @observable name?: string;

  constructor(props) {
    assign(this, props);
  }
}

/**
 * key: welcome-message-card
 */
export class OrganizationWelcomeMessage {
  @observable content: string;

  constructor(props) {
    assign(this, props);
  }
}

/**
 * key: 360-reflect-cards
 */
export class My360ReportReflectCardsContent {
  @observable explainer_card: string;
  @observable reflect_with_coach: string;
  @observable reflect_with_others: string;

  constructor(props) {
    assign(this, props);
  }
}

/**
 * key: team-summary-cards
 */
export class OrganizationContentTeamSummaryCards {
  @observable suggested_first_step: string;
  @observable cards: string[];
  @observable tips?: Record<TeamSummaryEmptyCardType, TeamSummaryCardTip>;

  constructor(props) {
    assign(this, props);
  }
}

/**
 * key: organization-quotes
 */
export class OrganizationContentQuote {
  @observable id?: string;
  @observable location: string;
  @observable icon: string;
  @observable owner: string;
  @observable content: string;
  @observable title: string;

  constructor(props) {
    assign(this, props);
  }
}

/**
 * key: organization testimonial overrides
 */
class OrganizationTestimonials {
  @observable name: string;
  @observable title: string;
  @observable organization: string;
  @observable testimonial: string;
  @observable hide_org_details?: boolean;

  constructor(props) {
    assign(this, props);
  }
}

export class OrganizationAlignTestimonials extends OrganizationTestimonials {
  constructor(props) {
    super(props);
    assign(this, props);
  }
}

export class OrganizationTeamPerspectiveTestimonials extends OrganizationTestimonials {
  constructor(props) {
    super(props);
    assign(this, props);
  }
}
export class OrganizationTeamHabitsTestimonials extends OrganizationTestimonials {
  constructor(props) {
    super(props);
    assign(this, props);
  }
}

export class OrganizationReflect360Testimonials extends OrganizationTestimonials {
  constructor(props) {
    super(props);
    assign(this, props);
  }
}

/**
 * Org Content type hinting definitions
 */

export type OrganizationContentContent =
  | OrganizationContentQuestion[]
  | OrganizationContentQuote[]
  | OrganizationContentWhatOthersSay[]
  | OrganizationWelcomeMessage[]
  | OrganizationAlignTestimonials[]
  | OrganizationTeamPerspectiveTestimonials[]
  | OrganizationTeamHabitsTestimonials[]
  | OrganizationReflect360Testimonials[]
  | OrganizationContentTeamSummaryCards
  | CustomOnboardingMarqueeCard[]
  | string
  | any[]
  | any;

/**
 * Content types map
 */
const contentTypes = {
  [OrganizationContent.OnboardingQuestions]: OrganizationContentQuestion,
  [OrganizationContent.OrganizationQuotes]: OrganizationContentQuote,
  [OrganizationContent.WhatOthersSay]: OrganizationContentWhatOthersSay,
  [OrganizationContent.TeamSummaryCards]: OrganizationContentTeamSummaryCards,
  [OrganizationContent.WelcomeMessageCard]: OrganizationWelcomeMessage,
  [OrganizationContent.AlignTestimonials]: OrganizationAlignTestimonials,
  [OrganizationContent.TeamPerspectiveTestimonials]: OrganizationTeamPerspectiveTestimonials,
  [OrganizationContent.TeamHabitsTestimonials]: OrganizationTeamHabitsTestimonials,
  [OrganizationContent.Reflect360Testimonials]: OrganizationReflect360Testimonials,
  [OrganizationContent.My360ReportReflectCardsContent]: My360ReportReflectCardsContent,
};

/**
 * Content type main model component
 */
export class OrganizationContentModel<
  T extends OrganizationContentContent = OrganizationContentContent
> extends Model {
  public static _store: OrganizationContentStore;

  @observable id: OrganizationContent;
  @observable type: OrganizationContentType;
  @observable organization_id: number;
  @observable content: T;
  @observable has_arguments: boolean;
  @observable arguments?: string;

  deserialize_content(content: T): void {
    // Handle when the content is a string
    if (isString(content)) {
      this.content = content;
      return;
    }

    const contentType = contentTypes[this.id];
    // Handle when the content is a plain object
    if (content instanceof Array === false) {
      this.handleObjectContent(contentType, content);
      return;
    }

    // Handle when the content is an array
    this.handleArrayContent(contentType, content);
  }

  handleArrayContent(contentType, content) {
    this.content = (content as any[]).map((rawContent, i) => {
      // If no matching contentType, return content
      if (!contentType) {
        return rawContent as T;
      }

      if (contentType instanceof Array) {
        return new contentType[i](rawContent) as T;
      }

      return new contentType(rawContent) as T;
    }) as T;
  }

  handleObjectContent(contentType, content) {
    // If no matching contentType, return content
    if (!contentType) {
      this.content = content;
      return;
    }

    this.content = new contentType(content);
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as OrganizationContentModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as OrganizationContentModel;
  }

  static get(id) {
    return this._get(id) as OrganizationContentModel;
  }
}

export default OrganizationContentModel;
