import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem } from 'app/models';
import IntelligenceActionResponseModel from 'app/models/IntelligenceActionResponseModel';
import Store from 'app/stores/Store';

export enum IntelligenceResponseType {
  PairInsights = 'pair_insights',
  CoachInsights = 'coach_insights',
}

export enum IntelligenceThreadType {
  Question = 'question',
}

export enum IntelligenceStrategy {
  PairPerspective = 'pair_perspective',
  PairReportQuestions = 'pair_report_questions',
  PairReportDynamicQuestions = 'pair_report_dynamic_questions',
}

export class IntelligenceActionStore extends Store<IntelligenceActionResponseModel> {
  @observable pairInsightsActionResponse = new ModelItem<IntelligenceActionResponseModel>(
    IntelligenceActionResponseModel
  );

  @observable coachInsightsActionResponse = new ModelItem<IntelligenceActionResponseModel>(
    IntelligenceActionResponseModel
  );

  constructor() {
    super();
    IntelligenceActionResponseModel._store = this;
  }

  public async sendAction(
    threadType: string,
    strategy: string,
    memberId: number,
    responseType: IntelligenceResponseType,
    params?: { [key: string]: any },
    fetchSilently = false
  ): Promise<any> {
    const url = ServerRouteHelper.api.intelligence.actions.send(
      threadType,
      strategy,
      memberId,
      this.sharedLinkToken
    );

    if (fetchSilently) {
      await this.apiService.get(url, params);
      return;
    }

    try {
      const responseTypeModel = this.getResponseModel(responseType);
      await responseTypeModel.load(url, params, {
        forceRefresh: true,
      });
    } catch (error) {
      Promise.reject(new Error('Failed to send action'));
    }
  }

  getResponseModel(responseType: string): ModelItem<IntelligenceActionResponseModel> {
    switch (responseType) {
      case IntelligenceResponseType.PairInsights:
        return this.pairInsightsActionResponse;
      case IntelligenceResponseType.CoachInsights:
        return this.coachInsightsActionResponse;

      default:
        throw new Error('Invalid response type');
    }
  }
}

export default IntelligenceActionStore;
