import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { EngageModel, ModelList, PagingMetaModel } from 'app/models';
import Store from 'app/stores/Store';

export class EngageStore extends Store<EngageModel> {
  @observable timezones = new ModelList<EngageModel>(EngageModel);

  @observable isCreatingEngage = false;
  @action setIsCreatingEngage = (isCreatingEngage: boolean): boolean =>
    (this.isCreatingEngage = isCreatingEngage);

  @observable isSavingComment = false;
  @action setIsSavingComment = (isSavingComment: boolean): boolean =>
    (this.isSavingComment = isSavingComment);

  @observable engages = new ModelList<EngageModel>(EngageModel);
  @observable pageMeta: PagingMetaModel;

  constructor() {
    super();
    EngageModel._store = this;
  }

  async createEngage(
    orgId: number,
    entityId: number,
    entityType: string,
    cadence: string,
    sendWeekday: number,
    timezone: string
  ): Promise<EngageModel | undefined> {
    try {
      const url = ServerRouteHelper.api.engage.create(orgId);

      const config = {
        url,
        data: {
          entity_id: entityId,
          entity_type: entityType,
          cadence,
          send_weekday: sendWeekday,
          timezone,
        },
        showGenericError: true,
      };

      this.setIsCreatingEngage(true);

      const resp = await this.apiService.newPost(config);

      if (resp?.data) {
        const newEngage = EngageModel.fromJson(resp.data) as EngageModel;
        this.engages.prependItem(newEngage);

        return newEngage;
      }
    } finally {
      this.setIsCreatingEngage(false);
    }
  }

  async saveComment(token: string, comment: string): Promise<void> {
    try {
      const url = ServerRouteHelper.api.engage.saveComment(token);

      const config = {
        url,
        data: {
          comment,
        },
        throwError: true,
      };

      this.setIsSavingComment(true);

      await this.apiService.newPost(config);
    } finally {
      this.setIsSavingComment(false);
    }
  }

  async getEngages(orgId: number, page?: number): Promise<void> {
    const params = page ? { page } : undefined;
    const url = ServerRouteHelper.api.engage.list(orgId, params);

    this.engages.load(url, undefined, {
      onResponse: (response) => {
        this.pageMeta = new PagingMetaModel(response.meta);
      },
    });
  }
}

export default EngageStore;
