import React from 'react';

import styles from './LoadingContent.module.scss';

interface LoadingProps {
  isLoading?: boolean;
}

/**
 * Use this to show a valence loading spinner as *content*.
 * (To fill the whole page with blank loading, use LoadingPage.)
 */
const LoadingContent: React.FC<LoadingProps> = ({ isLoading }) => {
  if (isLoading === false) {
    return null;
  }

  return (
    <div className={styles.loadingContent}>
      <div className={styles.loadingContainer}>
        <div className={styles.spin}>
          <img className="spin-logo" src="/images/valence-loading.webp" alt="loading..." />
        </div>
      </div>
    </div>
  );
};

export default LoadingContent;
