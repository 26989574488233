import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { RouteComponentProps, Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const Questions = lazy(() => import('./Questions'));
const SharedInvite = lazy(() => import('./Questions/SharedInvite'));
const Report = lazy(() => import('./Report'));
const TestDrive = lazy(() => import('./TestDrive'));
const TeamDiscuss = lazy(() => import('./TeamDiscuss'));

const PerspectiveRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route path={`${match.path}/questions`} component={Questions} />

      <Route exact path={`${match.path}/invite/:token`} component={SharedInvite} />
      <Route exact path={`${match.path}/invite/:teamId/:teamCode`} component={SharedInvite} />

      <Route path={`${match.path}/report`} component={Report} />

      <Route path={`${match.path}/test-drive/:token/:step`} component={TestDrive} />

      <Route path={`${match.path}/discuss/teams/:teamId/:step?`} component={TeamDiscuss} />
    </Switch>
  </Suspense>
);

export default <Route path="/perspective" component={PerspectiveRoutes} />;
