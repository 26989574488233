import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

export interface LinkProps {
  useLink?: boolean;
  url?: string;
  [attr: string]: any;
}

/**
 * Link allows you to switch between
 * <a> and react-router's <Link> easily via prop.
 */

const Link: React.FC<LinkProps> = ({ useLink, url, children, ...rest }) => {
  if (!!useLink) {
    return (
      <RouterLink to={url} {...rest}>
        {children}
      </RouterLink>
    );
  }

  return (
    <a href={url ?? '#'} {...rest}>
      {children}
    </a>
  );
};

export default Link;
