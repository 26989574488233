export const ENTITY_STATES = {
  SEEN_HABIT_WORKS_MODAL: 'seen_habit_works_modal',
  SEEN_TEAM_IMPROVEMENT_MODAL: 'seen_team_improvement_modal',
  SEEN_WELCOME_TO_MY360_MODAL: 'seen_welcome_to_my360_modal',
  SEEN_FOCUS_AREA_TOOLTIP: 'seen_focus_area_tooltip',
  SEEN_TEAM_INVITE_MEMBERS_TIP: 'seen_team_invite_members_tip',
  SEEN_ALIGN_REPORT_TEAM_RESULT: 'seen_align_report_team_result',
  SEEN_TEAM_PERSPECTIVE_RESULT: 'seen_team_perspective_result',
  SEEN_TEAM_PERSPECTIVE_DISCUSSION_CONCLUSION: 'seen_team_perspective_discussion_conclusion',
  SEEN_PERSPECTIVE_VISIBILITY_TOGGLE_NOTIFICATION:
    'seen_perspective_visibility_toggle_notification',

  SEEN_DISCUSSION_SPACE_INTRO: 'seen_discussion_space_intro',

  SUBSCRIBED_TO_EXERCISE_GUIDANCE: 'subscribed_exercise_to_guidance',
  NOT_USING_EXERCISE_DISCUSSION_GUIDE: 'not_using_exercise_discussion_guide',

  REQUESTED_RECEIVE_FACILITATION_GUIDANCE: 'requested_receive_facilitation_guidance',
  REQUESTED_GIVE_FACILITATION_GUIDANCE: 'requested_give_facilitation_guidance',

  MEMBER_ONBOARD: 'member_onboard',

  ONBOARDING_SHIFT_SOURCE: 'onboarding_shift_source',
  ONBOARDING_DESCRIBE_ROLE: 'onboarding_describe_role',
  ONBOARDING_MANAGER_DURATION: 'onboarding_manager_duration',
  ONBOARDING_ROLE_THINKING: 'onboarding_role_thinking',
  ONBOARDING_USER_SOURCE: 'onboarding_user_source',
  ONBOARDING_USER_INTENT: 'onboarding_user_intent',
  ONBOARDING_TEAMS: 'onboarding_teams',

  ONBOARDING_TEAM_TYPE: 'onboarding_team_type',
  ONBOARDING_TEAM_TYPE_REMOTE_DURATION: 'onboarding_team_type_remote_duration',
  ONBOARDING_TEAM_TYPE_REMOTE_PROSPECTS: 'onboarding_team_type_remote_prospects',

  MY360_SIGNED_UP_FOR_COACHING: 'my360_signed_up_for_coaching',
  MY360_EXPECTED_RESULT: 'my360_expected_result',
  MY360_HOW_YOU_FEEL: 'my360_how_you_feel',

  HIDDEN_COMPASSIONATE_CONVERSATIONS_CARD: 'hidden_compassionate_conversations_card',
  MANAGE_ALIGN_TOUR_INVITE_TEAM: 'manage_align_tour_invite_team',
  MANAGE_ALIGN_TOUR_COMPLETE_EXERCISE: 'manage_align_tour_complete_exercise',
  MANAGE_ALIGN_EXERCISE_LINK_COPIED: 'manage_align_exercise_link_copied',
  ALIGN_ON_DELETE_NOTES: 'align_on_delete_notes',

  PERSPECTIVE_IN_PROGRESS_QUESTION_ANSWERS: 'perspective_in_progress_question_answers',
  PERSPECTIVE_IN_PROGRESS_SURVEY_ANSWERS: 'perspective_in_progress_survey_answers',

  EXTREME_ALIGN_RESULTS_DETECTED: 'extreme_align_results_detected',
  INDIVIDUAL_PERSPECTIVE_INSIGHT_RESONATION: 'individual_perspective_insight_resonation',
  INDIVIDUAL_PERSPECTIVE_INSIGHTS_RATING: 'individual_perspective_insights_rating',
  INDIVIDUAL_PERSPECTIVE_DISCUSSION_TIP_VOTE: 'individual_perspective_discussion_tip_vote',
  POST_ALIGN_REPORT_PROMO_CARD_REMIND_LATER: 'post_align_report_promo_card_remind_later',
  ALIGN_INSIGHT_CARD_BOOKMARK: 'align_insight_card_bookmark',
  ALIGN_HELP_CONNECT_WITH_PEER: 'align_help_connect_with_peer',
  DISCUSSION_SPACE_FEEDBACK_RATING: 'discussion_space_feedback_rating',
  PAIR_PERSPECTIVE_OVERVIEW_VOTES: 'pair_perspective_overview_votes',

  ALIGN_PERSONAL_INSIGHT_VOTE: 'align_personal_insight_vote',

  SEEN_ALIGN_INSIGHTS_ON_TEAM_REPORT: 'seen_align_insights_on_team_report',
  MANAGER_SUBMITTED_FIRST_FEEDBACK_FOR_INSIGHTS: 'manager_submitted_first_feedback_for_insights',
  MANAGER_SUBMITTED_SECOND_FEEDBACK_FOR_INSIGHTS: 'manager_submitted_second_feedback_for_insights',

  USER_VIEWED_TEAM_RESULTS: 'user_viewed_team_results',
  USER_SUBMITTED_FIRST_TEAM_RESULTS_FEEDBACK: 'user_submitted_first_team_results_feedback',
  USER_SUBMITTED_SECOND_TEAM_RESULTS_FEEDBACK: 'user_submitted_second_team_results_feedback',
  USER_DISMISSED_TEAM_RESULTS_FEEDBACK: 'user_dismissed_team_results_feedback',
  USER_DISMISSED_ALIGN_REPORT_COACHING: 'user_dismissed_align_report_coaching',
  USER_DISMISSED_ALIGN_REPORT_WELCOME_CARD: 'user_dismissed_align_report_welcome_card',
  USER_DISMISSED_TEAM_SUMMARY_WELCOME_CARD: 'user_dismissed_team_summary_welcome_card',
  USER_VIEWED_PAIR_PERSPECTIVE: 'user_viewed_pair_perspective',
  USER_PAIRED_WITH_TEAM_MEMBER: 'user_paired_with_team_member',
  USER_INVITED_COLLEAGUE: 'user_invited_colleague',
  USER_DASHBOARD_ORGANIZATION: 'user_dashboard_organization',

  ALIGN_EXERCISE_MODAL_FEEDBACK: 'align_exercise_modal_feedback',

  SHOW_EDIT_TEAM_NAME: 'show_edit_team_name',
  DISMISSED_EDIT_TEAM_NAME_POPOVER: 'dismissed_edit_team_name_popover',

  ALIGN_STATEMENT_GUIDANCE_VOTE: 'align_statement_guidance_vote',

  MANAGE_ALIGN_EXERCISE_REPORT_SHARED: 'manage_align_exercise_report_shared',
  MANAGER_INVITED_USERS_TO_DISCUSSION_SPACE: 'manager_invited_users_to_discussion_space',
  MANAGER_DISMISSED_PERSPECTIVE_DISCUSSION_TAB: 'manager_dismissed_perspective_discussion_tab',
  MANAGER_DISMISSED_PLAYBOOK_WELCOME: 'manager_dismissed_playbook_welcome',
  TEAM_PERSPECTIVE_INTRO_SKIPPED_PERSPECTIVE: 'team_perspective_intro_skipped_perspective',
  TEAM_PERSPECTIVE_TAB_VISITED: 'team_perspective_tab_visited',
  TEAM_PERSPECTIVE_FIRST_INVITE_SENT: 'team_perspective_first_invite_sent',

  TEAM_GAME_MEMBER_DISMISSED_SHARING_SCREEN: 'team_game_member_dismissed_sharing_screen',

  DISMISSED_PILOT_WELCOME_CARD: 'dismissed_pilot_welcome_card',
  DISMISSED_TEAM_SUMMARY_ORG_QUOTE_CARD: 'dismissed_team_summary_org_quote_card',

  DISMISSED_TEAM_PERSPECTIVE_PAIRING_MARKETING_CARD:
    'dismissed_team_perspective_pairing_marketing_card',
  DISMISSED_TEAM_PERSPECTIVE_REPORTING_MARKETING_CARD:
    'dismissed_team_perspective_reporting_marketing_card',
  DISMISSED_TEAM_PERSPECTIVE_COACHING_MARKETING_CARD:
    'dismissed_team_perspective_coaching_marketing_card',

  CLICKED_PAIR_PERSPECTIVE_MARQUEE_CARD: 'clicked_pair_perspective_marquee_card',

  CUSTOM_ONBOARDING_MARQUEE_CARDS_INTERACTIONS: 'custom_onboarding_marquee_cards_interactions',
  MEMBER_PROMOTED_TO_CREATE_TEAM_AFTER_PERSPECTIVE:
    'member_promoted_to_create_team_after_perspective',
};
