import React, { Component, ReactNode } from 'react';

import { Button, Input, notification } from 'antd';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import validator from 'validator';

import './EmailDomainList.scss';

import { STORE_ORGANIZATION } from 'app/constants';
import { OrganizationModel } from 'app/models';
import EmailDomainModel from 'app/models/EmailDomainModel';
import { OrganizationStore } from 'app/stores';

interface EmailDomainListProps {
  organization: OrganizationModel;
  organizationStore?: OrganizationStore;
}

export class EmailDomainList extends Component<EmailDomainListProps> {
  @observable domains: EmailDomainModel[] = [];
  @action setDomains = (domains: EmailDomainModel[]): void => {
    this.domains = domains;
  };

  @observable isSaving = false;
  @action toggleIsSaving = (): void => {
    this.isSaving = !this.isSaving;
  };

  constructor(props: EmailDomainListProps) {
    super(props);
    this.setDomains(props.organization.email_domains);
  }

  @action
  handleChange = (oldDomain: string, newDomain: string): void => {
    const domainIndex = this.domains.findIndex((domain) => domain.domain === oldDomain);
    this.domains[domainIndex].domain = newDomain;
  };

  handleDelete = (domain: string): void => {
    this.domains = this.domains.filter((d) => d.domain !== domain);
  };

  handleAdd = (): void => {
    this.domains.push(new EmailDomainModel());
  };

  handleSave = async (): Promise<void> => {
    const { organization, organizationStore } = this.props;

    const validDomains = [];

    this.domains.forEach((domain) => {
      const isValid = this.isValidDomain(domain.domain);
      if (isValid) {
        validDomains.push(domain.domain);
      }

      if (!isValid) {
        notification.error({
          message: 'Oops!',
          description: domain.domain + ' is not a valid domain and was not saved.',
        });
        this.handleDelete(domain.domain);
      }
    });

    this.toggleIsSaving();

    try {
      await organizationStore.updateEmailDomains(organization.id, validDomains);

      notification.success({
        message: 'Saved!',
        description: 'Email domains successfully saved.',
      });
    } catch (err) {
      notification.error({
        message: 'Oops!',
        description: 'Something went wrong!',
      });
    } finally {
      this.toggleIsSaving();
    }
  };

  isValidDomain = (domain: string): boolean => {
    if (domain.startsWith('*.')) {
      domain = domain.substring(2);
    }

    return validator.isFQDN(domain);
  };

  deleteOptionDisabled = (domain: EmailDomainModel): string => {
    if (domain.has_settings) {
      return 'Has Settings';
    }
    return 'Delete';
  };

  render(): ReactNode {
    return (
      <div className="org-email-domains">
        <h4>Email Domains</h4>

        <p>Wildcard email domain are supported:</p>

        <ul>
          <li>
            wildcard email domain only works for subdomains. For example, if you have org which uses
            '@foo.com', and '*.foo.com', so you will need to add two emails domains for this org:
            <ol>
              <li>
                email domain as 'foo.com' (exact match) which will match all email addresses
                matching this domain. For example 'john@foo.com'
              </li>
              <li>
                wildCarded email domain '*.foo.com', which will match all emails addresses with
                subdomains. For example 'john@eu.foo.com', and 'mark@us.foo.com'
              </li>
            </ol>
          </li>
          <li>
            Wildcard email domains matches only higher subdomain. For example, wildcard
            '*.eu.test.com' will match 'mark@foo.eu.test.com', and 'john@bar.eu.test.com'. But, it
            will not match 'john@us.foo.eu.test.com'.
          </li>
          <li>
            Domains with settings cannot be deleted. The settings need to be removed before deleting
            the domain.
          </li>
        </ul>

        {this.domains.map((domain, index) => (
          <div className="domain-item" key={index} data-testid="domain-item">
            <div className="domain-input">
              <Input
                value={domain.domain}
                onChange={({ target }) => this.handleChange(domain.domain, target.value)}
              />
            </div>
            <div className="domain-delete">
              <Button
                type="link"
                size="small"
                onClick={() => this.handleDelete(domain.domain)}
                disabled={domain.has_settings}
              >
                {this.deleteOptionDisabled(domain)}
              </Button>
            </div>
          </div>
        ))}

        <div className="actions">
          <Button
            data-testid="new-btn"
            size="large"
            onClick={this.handleAdd}
            disabled={this.isSaving}
          >
            New Domain
          </Button>

          <Button
            data-testid="save-btn"
            type="primary"
            size="large"
            onClick={this.handleSave}
            disabled={!this.domains.length || this.isSaving}
          >
            Save Domains
          </Button>
        </div>
      </div>
    );
  }
}

export default inject(STORE_ORGANIZATION)(observer(EmailDomainList));
