import { observable } from 'mobx';

import EngageStore from 'app/stores/EngageStore';

import Model, { ModelJson } from './Model';

export class EngageModel extends Model {
  static _store: EngageStore;

  readonly id: number;
  @observable entity_id: number;
  @observable entity_type: string;
  @observable cadence: string;
  @observable send_weekday: number;
  @observable timezone: string;
  @observable started_at: string;
  @observable paused_at?: string;
  @observable created_at: string;
  @observable entity: { id: number; name: string };
  @observable send_weekday_human: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as EngageModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as EngageModel;
  }

  static get(id) {
    return this._get(id) as EngageModel;
  }
}

export default EngageModel;
