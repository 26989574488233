import TimezoneStore from 'app/stores/TimezoneStore';

import Model from './Model';

export class TimezoneModel extends Model {
  static _store: TimezoneStore;

  readonly name: string;
  readonly value: string;
}

export default TimezoneModel;
