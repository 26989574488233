import ApiRoutes from 'app/helpers/serverRoutes/ApiRoutes';
import { ApiService } from 'app/services/ApiService';

export const EventTypes = {
  ONBOARDING_COMPLETED: 'onboarding-completed',
  MARQUEE_CARD_CLICKED: 'marquee-card-clicked',
  MARQUEE_CARD_VIEWED: 'marquee-card-viewed',
  CONNECT_INVITE_SENT: 'connect-invite-sent',
  CONNECT_PROFILE_VIEW: 'connect-profile-view',
  REPORT_BENCHMARK_APPLIED: 'report-benchmark-applied',
  SINGLE_PLAYER_REPORT_CLOSED: 'single-player-report-closed',
  PERSPECTIVE_RESULT_SHARED: 'perspective-result-shared',
  WELCOME_VIDEO_VIEWED: 'welcome-video-viewed',
  MANAGE_TEAM_CTA_CLICKED: 'manage-team-cta-clicked',
  ONBOARDING_CAMPAIGN_COMPLETED: 'onboarding-campaign-completed',
  CHECKLIST_ITEM_CLICKED: 'checklist-item-clicked',
  ONBOARDING_PERSPECTIVE_INTRO_PAGE_CTA_CLICKED: 'onboarding-perspective-intro-page-cta-clicked',
  PERSPECTIVE_TEAM_REPORT_VIEWED: 'perspective-team-report-viewed',
  HONEYPOT_CLICK: 'honeypot-click',
  COLLEAGUE_TAKE_360: 'colleague-take-360-click',
  COACH_INSIGHTS_DRAWER_OPENED: 'coach-insights-drawer-opened',
};

export enum SinglePlayerReportCloseAction {
  Done = 'done_cta',
  LearnMore = 'learn_more_cta',
}

export interface VideoViewedEventData {
  duration: number;
  source: 'welcome';
  video_url: string;
}

class EventTriggerService {
  async send<T = Record<string, any>>(
    eventType: string,
    eventData: T,
    token: string = null
  ): Promise<void> {
    const url = ApiRoutes.tracking.trigger();
    const apiService = ApiService.getInstance();

    const config = {
      url,
      data: {
        event_data: eventData,
        event_type: eventType,
        token,
      },
    };

    try {
      await apiService.newPost(config);
    } catch {
      // noop
    }
  }
}

export default new EventTriggerService();
