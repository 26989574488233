import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const Engage = lazy(() => import('./Engage'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route path="/engage/:token/answer/:answer" component={Engage} />
    </Switch>
  </Suspense>
);
