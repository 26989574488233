import React, { Component, ReactElement } from 'react';

import cx from 'classnames';
import { inject, observer, Provider } from 'mobx-react';

// Shared styles with App layouts
import styles from './AdminAppLayout.module.scss';

import { AppLayoutUiStore } from 'app/components/features/AppLayout/stores';
import { STORE_MENU, STORE_ORGANIZATION } from 'app/constants';
import { MenuStore, OrganizationStore } from 'app/stores';

import NavMenu from './NavMenu';

export interface AdminAppLayoutProps {
  className?: string;

  menuStore?: MenuStore;
  organizationStore?: OrganizationStore;
}

/**
 * Admin Dashboard App Layout. A 2 column layout with a fixed left side menu
 * and content area on the left.
 */
export class AdminAppLayout extends Component<AdminAppLayoutProps> {
  appLayoutUiStore: AppLayoutUiStore;

  constructor(props: AdminAppLayoutProps) {
    super(props);
    this.init();
  }

  protected init = async (): Promise<void> => {
    this.appLayoutUiStore = new AppLayoutUiStore({
      menuStore: this.props.menuStore,
      organizationStore: this.props.organizationStore,
    });
    this.appLayoutUiStore.setUseRouter(false);
  };

  componentDidMount(): void {
    void this.appLayoutUiStore.boot();
  }

  render(): ReactElement {
    return (
      <Provider appLayoutUiStore={this.appLayoutUiStore}>
        <div className={cx(this.props.className, styles.appLayout)}>
          <aside className={styles.sideBar}>
            <NavMenu />
          </aside>
        </div>
      </Provider>
    );
  }
}

export default inject(STORE_MENU, STORE_ORGANIZATION)(observer(AdminAppLayout));
