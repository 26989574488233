import React from 'react';

import './LoadingPage.scss';

interface LoadingProps {
  isLoading?: boolean;
}

/**
 * Use this to fill the whole page with blank loading.
 * (To show a valence loading spinner as *content* (not filling the page), use LoadingContent.)
 */
const LoadingPage: React.FC<LoadingProps> = ({ isLoading }) => {
  if (isLoading === false) {
    return null;
  }

  return (
    <div className="loading-page">
      <div className="loading-container">
        <div className="spin">
          <img className="spin-logo" src="/images/valence-loading.webp" alt="loading..." />
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
