import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem } from 'app/models';
import DiscussionSpaceModel from 'app/models/DiscussionSpaceModel';
import Store from 'app/stores/Store';
export class DiscussionSpaceStore extends Store<DiscussionSpaceModel> {
  @observable public currentDiscussionSpace = new ModelItem<DiscussionSpaceModel>(
    DiscussionSpaceModel
  );

  constructor() {
    super();
    DiscussionSpaceModel._store = this;
  }

  loadByToken(token: string): Promise<void> {
    const url = ServerRouteHelper.api.discussionSpace.showByToken(token);
    return this.currentDiscussionSpace.load(url);
  }

  loadById(id: number): Promise<void> {
    const url = ServerRouteHelper.api.discussionSpace.show(id);
    return this.currentDiscussionSpace.load(url);
  }

  // Loads the active DS linked to exercise
  loadByExerciseId(exerciseId: number): Promise<void> {
    const url = ServerRouteHelper.api.discussionSpace.showByExerciseId(exerciseId);
    return this.currentDiscussionSpace.load(url);
  }

  updateTipFeedback(id: number, key: string, helpful: boolean): Promise<void> {
    const url = ServerRouteHelper.api.discussionSpace.tipFeedback(id);
    return this.apiService.post(url, { key, helpful });
  }

  sendInvites = (id: number, emails: string[], message: string): Promise<any> => {
    const url = ServerRouteHelper.api.discussionSpace.invite(id);
    return this.apiService.post(url, { emails, message });
  };
}

export default DiscussionSpaceStore;
