import { action, observable, ObservableMap } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import {
  ErrorCode,
  MenuModel,
  ModelItem,
  ModelList,
  OrganizationModel,
  TeamModel,
} from 'app/models';
import { RequestConfig } from 'app/services/ApiService';

import Store from './Store';

interface ActiveOrgAndTeam {
  active_organization: OrganizationModel;
  active_team: TeamModel;
}

export type ActiveOrgAndTeamResponse = { data: ActiveOrgAndTeam; errorCode: ErrorCode };

interface OrgAndTeam {
  orgId?: number;
  teamId?: number;
}

/**
 * Identifier for the different menus in the app (recognized by the backend Menus API).
 *
 * Corresponds to the various AbstractMenu.name() instances in the BE, Menu/Menus/*
 */
export enum MenuId {
  Main = 'main',
  OrgAnalytics = 'org_analytics',
  SecurityTools = 'security_tools',
  UserProfile = 'user_profile',
  UserProfileSettings = 'user_profile_settings',
}

export class MenuStore extends Store<MenuModel> {
  /** Keep track of the most recent menu request for each Menu type, so we can cancel the request if needed */
  private readonly lastMenuRequested = new Map<MenuId, OrgAndTeam>();

  @observable public menus = new ModelList<MenuModel>(MenuModel);

  @observable private readonly menuItemsTracker = new ObservableMap<MenuId, ModelItem<MenuModel>>();

  constructor() {
    super();
    MenuModel._store = this;
  }

  /**
   * Is the particular menu currently loading from the Backend?
   */
  isLoading(menuId: MenuId): boolean {
    return this.menuItemsTracker.get(menuId)?.loading ?? false;
  }

  @action
  public async loadMenu(menuId: MenuId, orgId?: number, teamId?: number): Promise<void> {
    const menuAlreadyLoading = this.menuItemsTracker.get(menuId)?.loading;
    if (menuAlreadyLoading) {
      // If this request is identical, do nothing (so we don't load the same menu twice)
      const inFlightMenu = this.lastMenuRequested.get(menuId);
      const alreadyLoading = inFlightMenu.orgId === orgId && inFlightMenu.teamId === teamId;
      if (alreadyLoading) {
        return;
      }

      // Otherwise, the currently loading request is stale (params have changed), so cancel it and process new request
      this.menuItemsTracker.get(menuId)?.cancelPreviousRequest();
    }

    // Get menu model
    const url = ServerRouteHelper.api.menus.menu(menuId, orgId, teamId);
    const menu = new ModelItem<MenuModel>(MenuModel);
    this.lastMenuRequested.set(menuId, { orgId, teamId });

    // Keep track of individual menu item loading status
    this.menuItemsTracker.set(menuId, menu);

    await menu.load(url);

    // Add menu to menu list
    if (menu.item) {
      this.menus.appendUniqueItems([menu.item]);
    }
  }

  public getMenu(menuId: MenuId): MenuModel {
    return this.menus.getItem(menuId);
  }

  /**
   * @returns the active model (in `{ data }`), or an error status code if the request failed. Notably, if the
   * requested team is not found in the requested org, the API returns `{ errorCode: ErrorCode.NOT_FOUND, ... }`
   *
   * Note that error codes outside of the `ErrorCode` enum can be returned (e.g. server errors).
   */
  async getActiveOrgIdAndTeam(orgId?: number, teamId?: number): Promise<ActiveOrgAndTeamResponse> {
    const url = ServerRouteHelper.api.menus.activeModels(orgId, teamId);
    const config: RequestConfig = {
      url,
      throwError: true,
    };

    try {
      const response = await this.apiService.newGet(config);
      return {
        data: response?.data,
        errorCode: undefined,
      };
    } catch (error) {
      return { errorCode: error?.status, data: undefined };
    }
  }
}

export default MenuStore;
