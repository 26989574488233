import { ServerRouteHelper } from 'app/helpers';
import { MemberModel } from 'app/models/MemberModel';
import { OrganizationModel } from 'app/models/OrganizationModel';
import { TeamModel } from 'app/models/TeamModel';

export const PERSPECTIVE_INVITE_TEXT = `Hi team,

I’ve signed our team up to do a quick assessment using one of Valence’s team tools called Perspective. It’s a tool to help understand yourself, uncover team blind spots, and find ways to work better together.

**How does it work?**

Everyone takes a 7 minute assessment to uncover their personal Perspective. Once the whole team is finished you’ll see a report that visualizes our results to highlight potential blind spots.`;

export const REMIND_MANAGER_EMAIL = (
  org: OrganizationModel,
  team: TeamModel,
  user: MemberModel
) => {
  const orgName = org ? `${org.name}'s` : '';
  const teamName = team.name;
  const url = `${window.location.origin}${ServerRouteHelper.dashboard.alignHome(
    team.organization_id,
    team.id
  )}`;

  return `Hi ${team.manager.item.name},

I noticed you hadn't started an Align exercise with your team, ${teamName} yet. It's important that all ${orgName} teams have ran Align, so that we can see where we're aligned and where we can improve.

[Click here to sign in to your team](${url}).

Please start an Align exercise by [DATE]

With thanks,

${user.name}`;
};

export const MANAGER_INVITE_EMAIL_TEXT = (
  memberName
) => `Your colleague ${memberName} has invited you to use Valence.

Valence offers managers a set of tools to help you get to the heart of what's needed to build happier, higher-performing teams.`;

export const ALIGN_REPORT_EMPTY_GUIDANCE_MESSAGE = `Most people agree that a feedback loop is probably the most important factor for change and growth. And most people want to know how they are doing - both when they are doing well, and when they need to do better. So then why is it still such a low scoring statement, across all teams?

The desire to avoid personal discomfort is the common root for many reasons for not giving feedback – I’m too busy, we’re already overworked, I’ve already tried – so overcoming your own awkwardness is necessary to improve.

This is a great place to start for creating a better team environment. To overcome this challenge and become a high performing team that can give and receive feedback well, you’ll need to accept that feedback is a skill that grows with time and practice.`;
