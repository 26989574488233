import React from 'react';

import AcceptTermsModal from 'app/components/features/AcceptTermsModal';
import CookiePrefFooter from 'app/components/features/CookiePrefFooter';
import ScrollToTop from 'app/components/ui/ScrollToTop/ScrollToTop';
import adminRoutes from 'app/pages/admin/routes';
import alignRoutes from 'app/pages/align/routes';
import authRoutes from 'app/pages/auth/routes';
import dashboardRoutes from 'app/pages/dashboard/routes';
import discussionSpaceRoutes from 'app/pages/discussion-space/routes';
import engageRoutes from 'app/pages/engage/routes';
import managerPlaybookRoutes from 'app/pages/manager-playbook/routes';
import memberRoutes from 'app/pages/member/routes';
import my360Routes from 'app/pages/my360/routes';
import onboardingRoutes from 'app/pages/onboarding/routes';
import orgNoticeRoutes from 'app/pages/org-notices/routes';
import organizationRoutes from 'app/pages/organization/routes';
import perspectiveRoutes from 'app/pages/perspective/routes';
import pulseRoutes from 'app/pages/pulse/routes';
import pulseReportRoutes from 'app/pages/PulseReport/routes';
import referralsRoutes from 'app/pages/referrals/routes';
import singlePlayerRoutes from 'app/pages/single-player/routes';
import teamConnectRoutes from 'app/pages/team-connect/routes';
import teamRoutes from 'app/pages/teams/routes';

export default (
  <>
    <ScrollToTop exclude={['/perspective/report/pair']} />
    {authRoutes}
    {perspectiveRoutes}
    {alignRoutes}
    {singlePlayerRoutes}
    {my360Routes}
    {referralsRoutes}
    {teamRoutes}
    {dashboardRoutes}
    {adminRoutes}
    {managerPlaybookRoutes}
    {discussionSpaceRoutes}
    {organizationRoutes}
    {memberRoutes}
    {onboardingRoutes}
    {pulseReportRoutes}
    {teamConnectRoutes}
    {pulseRoutes}
    {orgNoticeRoutes}
    {engageRoutes}
    <AcceptTermsModal />
    <CookiePrefFooter />
  </>
);
