import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import AppLayout from 'app/components/features/AppLayout';
import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

import CustomLocalizations from './CustomLocalizations/CustomLocalizations';
import InsightStats from './InsightStats';
import OrgContent from './OrgContent';
import PulseTemplates from './PulseTemplates';
import CreatePulseTemplate from './PulseTemplates/Create';
import EditPulseTemplate from './PulseTemplates/Edit';
import UsageData from './UsageData';

const SecurityTools = lazy(() => import('./SecurityTools'));

const AppLayoutWrapper = (props) => {
  return (
    <AppLayout>
      <Route path="/admin-panel/security-tools" component={SecurityTools} />
    </AppLayout>
  );
};

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route
        path="/admin-panel/custom-localizations/:organization_id?"
        component={CustomLocalizations}
      />

      <Route path="/admin-panel/insight-stats" component={InsightStats} />

      <Route path="/admin-panel/org-contents" component={OrgContent} />

      <Route path="/admin-panel/pulse/templates/list" component={PulseTemplates} />
      <Route path="/admin-panel/pulse/templates/new" component={CreatePulseTemplate} />
      <Route path="/admin-panel/pulse/templates/:templateId/edit" component={EditPulseTemplate} />
      <Route path="/admin-panel/usage-data" component={UsageData} />

      <Route path="/admin-panel/security-tools" component={AppLayoutWrapper} />
    </Switch>
  </Suspense>
);
