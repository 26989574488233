/**
 * ApiService uses this error to throw when the call was explicitly cancelled by us (axios cancel),
 * and the ApiService caller opted in via the `RequestConfig.throwCancelAsError` option.
 */
export class ApiServiceCallCancelledError extends Error {
  constructor(m: string) {
    super(m);

    // Set the prototype explicitly.
    // https://github.com/microsoft/TypeScript-wiki/blob/main/FAQ.md#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, ApiServiceCallCancelledError.prototype);
  }
}
