import React, { Component, ReactNode } from 'react';

import cx from 'classnames';

import './AppHeader.scss';

import { NavbarLogo } from 'app/components/ui/Logo';

import Breadcrumbs from './Breadcrumbs';

export interface AppHeaderProps {
  leftComponent?: ReactNode;
  centerComponent?: ReactNode;
  rightComponent?: ReactNode;
  hideLogo?: boolean;
  isSeamless?: boolean;
  breadcrumbs?: {
    link?: string;
    title: string;
  }[];
  className?: string;
  forceLayout?: any;
}

export class AppHeader extends Component<AppHeaderProps> {
  styleForForcedLayout = (forceLayout) => (forceLayout ? { width: `${100.0 / 3.0}%` } : {});

  get leftContainer(): ReactNode {
    return (
      <div style={this.styleForForcedLayout(this.props.forceLayout)} className="left-container">
        {!this.props.hideLogo && (
          <NavbarLogo wrapperClassName="d-flex align-items-center justify-content-start" />
        )}
        {this.props.breadcrumbs && <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />}
        {this.props.leftComponent}
      </div>
    );
  }

  get rightContainer(): ReactNode {
    return (
      <div style={this.styleForForcedLayout(this.props.forceLayout)} className="right-container">
        {this.props.rightComponent}
      </div>
    );
  }

  get centerContainer(): ReactNode {
    return (
      <div
        style={this.styleForForcedLayout(this.props.forceLayout)}
        className="flex-fill d-flex align-items-center justify-content-center center-container"
      >
        {this.props.centerComponent}
      </div>
    );
  }

  render(): ReactNode {
    return (
      <div
        className={cx('app-header container-fluid', 'sticky-top', this.props.className, {
          'app-header-seamless': this.props.isSeamless,
        })}
      >
        <nav className="navbar bg-white d-flex flex-column flex-sm-row align-items-md-center">
          <div className="container-fluid">
            <div className="header-container w-100">
              <div className="flank">
                {this.leftContainer}
                {this.rightContainer}
              </div>

              {this.centerContainer}
            </div>

            <div className="header-container-mobile w-100">
              <div className="header-upper-part">
                {this.leftContainer}
                {this.centerContainer}
              </div>

              {this.rightContainer}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default AppHeader;
