/**
 * Represents which "step" or in the current UI, "tab" the user is on in the Align Report.
 *
 * #### A note of warning:
 * Although the the enum string value is "often" used to append to the report URL, it's not always. E.g.
 * - REPORT is not a tab, it's the tabless report URL — which, when it loads, redirects to TEAM (tab 1)
 * - COACH tab won't render its actual content without the hash '#insights'. Without that, just renders TEAM's content.
 */
export enum AlignReportSteps {
  REPORT = 'report',
  TEAM = 'team',
  PERSONAL = 'personal',
  COACH = 'coach',
  DISCUSSION = 'discussion',
}

/**
 * Use this as a type for only the *tabs* of the Align Report (i.e. when building URL by appending)
 */
export type AlignReportTabs = Exclude<AlignReportSteps, AlignReportSteps.REPORT>;
