import { ReactNode } from 'react';

import { action, computed, observable, when } from 'mobx';
import { matchPath } from 'react-router';

import { ServerRouteHelper } from 'app/helpers';
import {
  ErrorCode,
  MenuItemModel,
  MenuItemTypes,
  MenuModel,
  OrganizationModel,
  TeamModel,
} from 'app/models';
import NavService from 'app/services/NavService';
import ThirdPartyService from 'app/services/ThirdPartyService';
import { MenuId, MenuStore, OrganizationStore } from 'app/stores';

export interface ActiveOrgAndTeam {
  org: OrganizationModel;
  team: TeamModel;
}

export interface AppLayoutUiStoreProps {
  menuStore: MenuStore;
  organizationStore: OrganizationStore;
}

export default class AppLayoutUiStore {
  menuStore: MenuStore;
  organizationStore: OrganizationStore;

  constructor(props: AppLayoutUiStoreProps) {
    this.menuStore = props.menuStore;
    this.organizationStore = props.organizationStore;
  }

  /**
   * Only to be called from AppLayout and AppAdminLayout (componentDidMount)
   */
  public async boot(): Promise<void> {
    await this.loadActiveOrgAndTeam();
  }

  @observable activeOrgAndTeam: ActiveOrgAndTeam = {
    org: null,
    team: null,
  };
  @action private readonly setActiveOrgAndTeam = (
    org: OrganizationModel,
    team: TeamModel
  ): void => {
    this.activeOrgAndTeam = { org, team };
  };

  @computed
  get activeTeam(): TeamModel {
    return this.activeOrgAndTeam.team;
  }

  /**
   * @deprecated Outside of `AppLayoutUiStore`, current instruction is "we need to stop referencing this property;
   * - Externally: To know "the current organization", reference `OrganizationStore.organization.item?` instead.""
   * - Internally: You can reference this.activeOrgInternal to avoid depreciation warnings from this property.
   */
  @computed get activeOrganization(): OrganizationModel {
    return this.activeOrgAndTeam.org;
  }

  /**
   * Version of activeOrganization to use internally within AppLayoutUiStore (without deprecation warnings)
   * @returns this.activeOrganization
   */
  @computed
  private get activeOrgInternal(): OrganizationModel {
    return this.activeOrganization;
  }

  /**
   * For unit test setup only. No one outside of this class should be setting active Org manually, but this methods
   * exists as a workaround for unit test. Explicitly named to make it obvious it breaks encapsulation.
   * - If you (not unit test) want to set the org, you should call `updateLayoutOrg()` or `loadActiveOrgAndTeam()`
   */
  public unitTestSetActiveOrganization = (organization: OrganizationModel): void => {
    this.setActiveOrgAndTeam(organization, this.activeTeam);
  };

  @observable path: any;
  @action setPath = (path: string): void => {
    this.path = path;
  };

  @observable isLoading = false;
  @action setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading;
  };

  /**
   * Toggle the display or hiding of the app layout sidebar,
   * where the primary menu and logo are displayed
   */
  @observable showSidebar = false;

  @action setShowSidebar = (showSidebar: boolean): void => {
    this.showSidebar = showSidebar;
  };

  @action toggleSidebar = (): void => {
    this.showSidebar = !this.showSidebar;
  };

  @observable sidebarNavMenuOpen = false;
  @action toggleSidebarNavMenu = (): void => {
    this.sidebarNavMenuOpen = !this.sidebarNavMenuOpen;
  };

  /**
   * Page UI bar title
   */
  @observable title: string = null;
  @action setTitle = (title: string): void => {
    this.title = title;
  };

  /**
   * Page UI bar first left element after the title.
   */
  @observable primaryElement: ReactNode = null;
  @action setPrimaryElement = (primaryElement: ReactNode): void => {
    this.primaryElement = primaryElement;
  };

  /**
   * Page UI bar second left element after the title.
   */
  @observable secondaryElement: ReactNode = null;
  @action setSecondaryElement = (secondaryElement: ReactNode): void => {
    this.secondaryElement = secondaryElement;
  };

  /**
   * Primary UI bar top right first CTA
   */
  @observable primaryCta: ReactNode = null;
  @action setPrimaryCta = (primaryCta: ReactNode): void => {
    this.primaryCta = primaryCta;
  };

  /**
   * Primary UI bar top right second CTA
   */
  @observable secondaryCta: ReactNode = null;
  @action setSecondaryCta = (secondaryCta: ReactNode): void => {
    this.secondaryCta = secondaryCta;
  };

  /**
   * Set if history routing is supported on the current layout.
   *
   * Use router will determine if links that can be history routed are able to.
   * If this is false all links will use normal `href` links. If this is true the menu
   * item settings will determine if history routing is used or not.
   */
  @observable useRouter = true;
  @action setUseRouter = (status: boolean): void => {
    this.useRouter = status;
  };

  @computed
  get hasActiveTeam(): boolean {
    return !!this.activeTeam;
  }

  /**
   * Reset and set all top nav components in a single callback.
   *
   * This was done to prevent `resetComponents()` from being called after top
   * nav components had been set. This happened when resetComponents() was
   * called from a reaction or autorun and triggered after the top nav was
   * already setup.
   *
   * By combining the reset and the setup of the top nav into a single call they
   * are never called separately and always called together and we never have an
   * instance where reset accidentally clears active top nav components.
   */
  public setTopNav = (callBack?: (store: AppLayoutUiStore) => void, manualTitle?: string): void => {
    // Clear all components
    this.resetComponents();

    // Build and set title
    manualTitle ? this.updateTitle(manualTitle) : this.whenReadyTitleFromBreadcrumb();

    if (callBack) {
      callBack(this);
    }
  };

  /**
   * Graceful handle updating the title from the menu. This prevents components
   * form needing to setup the when.
   */
  private readonly whenReadyTitleFromBreadcrumb = (): void => {
    when(
      () => !!this.menu && !!this.path,
      () => this.handleTitleFromBreadcrumb()
    );
  };

  /**
   * Only call this from places that SHOULD update the "active org", _AND_ the FE does *not* know which team should be
   * active.
   *
   * "Danger, get any call to this function explicitly reviewed."
   *
   * This function is designed to specify/change the active org.
   * - The method tries to not make a change unless specified. This means
   *   1. If you call it with the existing active org, it won't "reset" certain AppLayout... _stuff_.
   *   2. If you call it with the existing active org, it will make the call defaulting with the _existing_ active
   *      team too. If you **know** which team is active, don't call this, call `updateLayoutOrgAndTeam()` instead.
   *
   * This function will make the call to reload active menu, checks if the team is in the org, and initiates loading
   * the org. By the time this async method is finished, organizationStore.organization will be loaded with the org.
   *
   * @param organizationId current/new FE org
   */
  public updateLayoutOrg = (organizationId: number): Promise<void> => {
    return this.updateLayout(organizationId);
  };

  /**
   * Only call this from places that SHOULD update the "active org" AND the "active team",
   * especially from a page that drives the active org and active team from the page route params.
   *
   * "Danger, get any call to this function explicitly reviewed."
   *
   * This function is intended to specify/change the BE active org & team, not RETRIEVE them
   * - The method tries to not make a change unless specified. This means
   *   1. If you call it with the existing active org, it won't "reset" certain AppLayout... _stuff_.
   *   2. If you pass null for the orgId, it will default to using the _existing_ active org. This is to help the
   *      transition to orgId routes, as pages that still don't use orgId route param can still call this method
   *      with the teamId from the route.
   *
   * If you switched orgs and don't know which team should be the current active team, call `updateLayoutOrg()` instead.
   *
   * This function will make the call to reload active menu, checks if the team is in the org, and initiates loading
   * the org. By the time this async method is finished, organizationStore.organization will be loaded with the org.
   *
   * @param organizationId current/new FE org. if not provided, the existing active organization will be used
   * @param teamId current/new FE team
   */
  public updateLayoutOrgAndTeam = async (orgId: number | null, teamId: number): Promise<void> => {
    await this.updateLayout(orgId, teamId);
  };

  /**
   * See all the warnings on the above "updateLayout..." functions.
   *
   * @param organizationId if null or 0 is passed, will default to current active org
   * @param teamId if null is passed, the existing active team will be used (unless the org has changed, in which case
   * the default team is "no team, BE you tell me which team is active")
   */
  private readonly updateLayout = (organizationId: number, teamId?: number): Promise<void> => {
    // Default to "don't change, unless the caller is explicitly making a change"
    const loadOrgId = organizationId > 0 ? organizationId : this.activeOrgInternal?.id;

    if (loadOrgId !== this.activeOrgInternal?.id) {
      // If this is an org change, we perform a "reset", see original resetComponents() PR for reasons why
      this.resetComponents();
      this.setActiveOrgAndTeam(null, null); // Retained for historical & PR reasons. Unsure here is best (Feb 2025)
    }

    // Do this after "org changed" reset, in case active team was cleared, to avoid falling back to a team in old org.
    const loadTeamId = teamId ?? this.activeTeam?.id;

    const changed = loadOrgId !== this.activeOrgInternal?.id || loadTeamId !== this.activeTeam?.id;
    if (changed) {
      return this.loadActiveOrgAndTeam(loadOrgId, loadTeamId);
    }
  };

  /**
   * After a team is deleted, it could be the active team, which could cause some side effects
   * So in this case we need to refresh the page
   */
  public reloadIfDeletedTeamWasActiveTeam(deletedTeamId: number): void {
    if (deletedTeamId === this.activeTeam?.id) {
      window.location.reload();
    }
  }

  /**
   * Don't call this method directly. Call one of the `updateLayout...()` methods instead.
   *
   * A lot of discussion happened about this method. Please be ware, this is a dangerous place.
   * Updating the layout org and team is a top level method, that should be called once the page is mounted,
   * or if there an org/team in the URL detected.
   *
   * If we allow this endpoint to be called from different places, it would cause conflict, as we could end up having
   * an active organization from the BE, different than the active organization in the URL.
   */
  private readonly loadActiveOrgAndTeam = async (
    orgId?: number,
    teamId?: number
  ): Promise<void> => {
    this.setIsLoading(true);

    try {
      const response = await this.menuStore.getActiveOrgIdAndTeam(orgId, teamId);

      if (response.errorCode === ErrorCode.NOT_FOUND) {
        ThirdPartyService.sentry.captureMessage(
          "AppLayoutUiStore.loadActiveOrgAndTeam unexpected response 'Team not found in org'. " +
            ` Called with orgId: ${orgId}, teamId: ${teamId}. ` +
            `Current location: ${window.location.pathname}, search ${window.location.search}`
        );

        // The requested team is not in the requested org. We're now in invalid state. Reload a known page to save UX.
        if (teamId) {
          window.location.href = ServerRouteHelper.dashboard.teams.home(teamId);
          return;
        }

        if (orgId) {
          window.location.href = ServerRouteHelper.dashboard.organization.summary(orgId);
          return;
        }

        window.location.href = ServerRouteHelper.dashboard.home();
        return;
      }

      if (!response.data) {
        // If there's any other kind of error on the API call, not sure what to do, but historically in this
        // case we _just didn't do anything_, so I'll keep that at this time (Feb 2025).
        return;
      }

      this.setActiveOrgAndTeam(response.data.active_organization, response.data.active_team);
      await this.organizationStore.getOrganizationById(response.data.active_organization.id);
    } finally {
      this.setIsLoading(false);
    }
  };

  /**
   * Update the title based on breadcrumbs
   */
  public handleTitleFromBreadcrumb = (): void => {
    if (!this.menu || !this.path) {
      return;
    }

    const pathItem = this.currentMenuItem();

    if (pathItem) {
      this.setTitleFromItem(pathItem);
      return;
    }

    // If no path found, ensure we clear the title
    this.setTitle(null);
  };

  /**
   * Reset all top nav components, excluding the title.
   */
  @action
  protected resetComponents = (): void => {
    this.setPrimaryElement(null);
    this.setSecondaryElement(null);
    this.setPrimaryCta(null);
    this.setSecondaryCta(null);
  };

  /**
   * Set the main top nav title using a menu item.
   */
  protected setTitleFromItem(item: MenuItemModel): void {
    if (!item?.breadcrumb) {
      return;
    }

    if (item.breadcrumb.length > 0) {
      this.updateTitle(item.breadcrumb.join(' / '));
      return;
    }

    this.updateTitle(item.name);
  }

  @computed
  get menu(): MenuModel {
    return this.menuStore.getMenu(MenuId.Main);
  }

  currentMenuItem(menuItems: MenuItemModel[] = null): MenuItemModel {
    // Fallback to main app dashboard menu if none supplied
    const itemsToSearch = menuItems || this.menu.menu_items;

    return this.findMenuItem(itemsToSearch);
  }

  /**
   * Recursively look for a matching menu item based on the current path.
   *
   * We avoid using things like `Array.prototype.find()`` as they make recursion
   * difficult and you end up finding the top level parent, instead of the
   * deepest nested child.
   */
  protected findMenuItem(items: MenuItemModel[]): MenuItemModel {
    for (const item of items) {
      // Check if item matches
      if (this.menuItemPathMatch(item)) {
        return item;
      }

      let foundItem = null;

      // Check the children
      if (item.children.length > 0) {
        foundItem = this.findMenuItem(item.children);
      }

      // Only stop looping when we have a match
      if (foundItem) {
        return foundItem;
      }
    }
  }

  /**
   * Check if menu item match our path and item type.
   */
  protected menuItemPathMatch(item: MenuItemModel): boolean {
    // Only links and hidden declared items can be matched to a path and used for titles
    const types = [MenuItemTypes.Link, MenuItemTypes.Declared, MenuItemTypes.Component];

    if (!types.includes(item.type)) {
      return false;
    }

    const pathMatchOptions = { path: item.token_path, exact: true };
    return item.url === this.path || !!matchPath(this.path, pathMatchOptions);
  }

  protected updateTitle(title: string): void {
    this.setTitle(title);
    NavService.setHeadTitle(title);
  }
}
