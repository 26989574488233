import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ExerciseTypeMetricsModel, ModelItem } from 'app/models';
import Store from 'app/stores/Store';

export class ExerciseTypeMetricsStore extends Store<ExerciseTypeMetricsModel> {
  @observable public alignExerciseTypeMetrics = new ModelItem<ExerciseTypeMetricsModel>(
    ExerciseTypeMetricsModel
  );
  @observable public reflect360ExerciseTypeMetrics = new ModelItem<ExerciseTypeMetricsModel>(
    ExerciseTypeMetricsModel
  );

  constructor() {
    super();
    ExerciseTypeMetricsModel.setStore(this);
  }

  public async loadAlignMetrics(orgId: number, params: Record<string, any>): Promise<void> {
    const url = ServerRouteHelper.api.organizations.exercisTypeMetrics(orgId);
    await this.alignExerciseTypeMetrics.load(url, { filters: params });
  }

  public async loadReflect360Metrics(orgId: number, params: Record<string, any>): Promise<void> {
    const url = ServerRouteHelper.api.organizations.exercisTypeMetrics(orgId);
    await this.reflect360ExerciseTypeMetrics.load(url, { filters: params });
  }
}

export default ExerciseTypeMetricsStore;
