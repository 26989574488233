import { action, computed, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList } from 'app/models';
import { Features } from 'app/models';
import FeatureModel from 'app/models/FeatureModel';
import Model from 'app/models/Model';
import Store from 'app/stores/Store';

export const FEATURES = {
  SINGLE_PLAYER_ENABLED: 'SinglePlayerAlignEnabled',
};

export class FeatureStore extends Store<Model> {
  @observable orgFeatures = new ModelList<FeatureModel>(FeatureModel);

  constructor() {
    super();
    FeatureModel._store = this;
  }

  isOrgFeatureEnabled(name: string): boolean {
    const feature = this.orgFeatures.items?.find((feature) => feature.name === name);
    return feature?.status === 'on' || !!feature?.enabled;
  }

  @action
  async loadOrgFeatures(orgId: number): Promise<void> {
    const url = ServerRouteHelper.api.features.orgEnabled(orgId);
    this.orgFeatures.load(url);
  }

  @computed
  get isLoadingOrgFeatures(): boolean {
    return this.orgFeatures.loading;
  }

  /**
   * Hide display of member personal details based on feature flag.
   *
   * TODO: This should be come its own feature flag currently piggy backing on
   * "HideAlignPreview" feature flag.
   */
  @computed
  get anonymizeMemberDisplay(): boolean {
    // While its loading return true so
    // content doesn't show and flicker to be hidden
    return this.isLoadingOrgFeatures || this.isOrgFeatureEnabled(Features.HideAlignPreview);
  }

  /**
   * This is a legacy feature replaced by org content "team-promo-cards"
   *
   * @todo remove
   */
  @computed
  get perspectiveIsSuggestedFirstStep(): boolean {
    return (
      this.isLoadingOrgFeatures ||
      this.isOrgFeatureEnabled(Features.UsePerspectiveAsFirstSuggestedStep)
    );
  }

  @computed
  get isMarketingWelcomePageTabDisabled(): boolean {
    return (
      this.isLoadingOrgFeatures || this.isOrgFeatureEnabled(Features.MarketingHomepageTabDisabled)
    );
  }

  @computed
  get isTeamPerspectiveDisabled(): boolean {
    return !this.isLoadingOrgFeatures && this.isOrgFeatureEnabled(Features.TeamPerspectiveDisabled);
  }

  @computed
  get isBillingHoneyPotElementsDisabled(): boolean {
    return (
      !this.isLoadingOrgFeatures &&
      this.isOrgFeatureEnabled(Features.DisableBillingHoneypotElements)
    );
  }

  @computed
  get isSmartInsightsDisabled(): boolean {
    return !this.isLoadingOrgFeatures && this.isOrgFeatureEnabled(Features.DisableSmartInsights);
  }
}
export default FeatureStore;
