import { MenuItemModel } from 'app/models';

import Store from './Store';

export class MenuItemStore extends Store<MenuItemModel> {
  constructor() {
    super();
    MenuItemModel._store = this;
  }
}

export default MenuItemStore;
