import { observable } from 'mobx';

import PlainModel from './PlainModel';

export class EmailDomainModel extends PlainModel {
  readonly id: number;
  @observable organization_id: number;
  @observable domain: string;
  @observable has_settings: boolean;
}

export default EmailDomainModel;
