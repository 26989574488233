import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList, OrganizationTeamListMetricsModel, PagingMetaModel } from 'app/models';
import Store from 'app/stores/Store';

export class OrganizationTeamListMetricsStore extends Store<OrganizationTeamListMetricsModel> {
  @observable public metrics = new ModelList<OrganizationTeamListMetricsModel>(
    OrganizationTeamListMetricsModel
  );

  @observable pageMeta: PagingMetaModel;

  constructor() {
    super();
    OrganizationTeamListMetricsModel.setStore(this);
  }

  public async loadTeamList(
    orgId: number,
    filters: Record<string, any>,
    pageNumber: number = null
  ): Promise<void> {
    const url = ServerRouteHelper.api.organizations.teamList(orgId);

    const params = { filters: filters };

    if (pageNumber) {
      params['page'] = pageNumber;
    }

    await this.metrics.load(url, params, {
      onResponse: (response) => {
        this.pageMeta = new PagingMetaModel(response.meta);
      },
    });
  }
}

export default OrganizationTeamListMetricsStore;
