import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList } from 'app/models';
import {
  IntelligenceQuestionModel,
  IntelligenceQuestionType,
} from 'app/models/IntelligenceQuestionModel';
import Store from 'app/stores/Store';

export class IntelligenceQuestionStore extends Store<IntelligenceQuestionModel> {
  @observable templateQuestions = new ModelList<IntelligenceQuestionModel>(
    IntelligenceQuestionModel
  );

  @observable dynamicQuestions = new ModelList<IntelligenceQuestionModel>(
    IntelligenceQuestionModel
  );

  constructor() {
    super();
    IntelligenceQuestionModel._store = this;
  }

  public async loadIntelligenceQuestions(
    memberId: number,
    type?: IntelligenceQuestionType
  ): Promise<void> {
    const url = ServerRouteHelper.api.intelligence.questions.getQuestions(memberId);

    const response = await this.apiService.get(url);

    if (type !== undefined) {
      const questionsType = this.resolveQuestionType(type);
      const responseQuestions = response[type];
      questionsType.setItems(
        responseQuestions.map((item) => IntelligenceQuestionModel.fromJson(item))
      );
      return;
    }

    this.templateQuestions.setItems(
      response.template.map((item) => IntelligenceQuestionModel.fromJson(item))
    );
    this.dynamicQuestions.setItems(
      response.dynamic.map((item) => IntelligenceQuestionModel.fromJson(item))
    );
  }

  public resolveQuestionType(type: IntelligenceQuestionType): ModelList<IntelligenceQuestionModel> {
    switch (type) {
      case IntelligenceQuestionType.Template:
        return this.templateQuestions;
      case IntelligenceQuestionType.Dynamic:
        return this.dynamicQuestions;
      default:
        throw new Error('Invalid question type');
    }
  }
}

export default IntelligenceQuestionStore;
