import React, { ReactNode } from 'react';

import {
  BookOutlined,
  DollarOutlined,
  DownOutlined,
  LogoutOutlined,
  ReadOutlined,
  StopOutlined,
  TagOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { inject } from 'mobx-react';

import { AppLayoutUiStore } from 'app/components/features/AppLayout/stores';
import Link from 'app/components/ui/Link';
import { STORE_APP_LAYOUT_UI } from 'app/constants';
import { MenuItemModel } from 'app/models';

const icons = {
  book_outlined: BookOutlined,
  down_outlined: DownOutlined,
  logout_outlined: LogoutOutlined,
  read_outlined: ReadOutlined,
  stop_outlined: StopOutlined,
  tag_outlined: TagOutlined,
  dollar_outlined: DollarOutlined,
  team_outlined: TeamOutlined,
};

const makeIcon = (menuItem: MenuItemModel): ReactNode => {
  if (!menuItem.icon) {
    return null;
  }

  const icon = icons[menuItem.icon];
  return React.createElement(icon);
};

const makeLabelTag = (menuItem: MenuItemModel): ReactNode => {
  if (!menuItem.label) {
    return null;
  }

  return <Tag>{menuItem.label}</Tag>;
};

interface MenuLinkProps {
  menuItem: MenuItemModel;
  className?: string;
  isLoading?: boolean;
  appLayoutUiStore?: AppLayoutUiStore;
}

/**
 * This component wraps the Link component and adds logic for using the routed link or plain href.
 * It also renders the menu *without a link* while `isLoading` is true
 */
const MenuLink: React.FC<MenuLinkProps> = ({
  menuItem,
  appLayoutUiStore,
  /** Pass true to render the menu just as text, i.e. unclickable, e.g. while a new menu is loading */
  isLoading,
  children,
  ...rest
}) => {
  if (isLoading) {
    return (
      <span {...rest}>
        <LinkContent menuItem={menuItem}>{children}</LinkContent>
      </span>
    );
  }

  const useLink = !!appLayoutUiStore?.useRouter && menuItem.routed;
  let url = menuItem.url;

  if (menuItem.appends_referrer) {
    url += `?referrer=${window.location.pathname}`;
  }

  return (
    <Link url={url} {...rest} useLink={useLink}>
      <LinkContent menuItem={menuItem}>{children}</LinkContent>
    </Link>
  );
};

interface LinkContentProps {
  menuItem: MenuItemModel;
}

const LinkContent: React.FC<LinkContentProps> = ({ menuItem, children }) => {
  const icon = makeIcon(menuItem);
  const label = makeLabelTag(menuItem);

  return (
    <>
      {icon} {children ?? menuItem.name} {label}
    </>
  );
};

export default inject(STORE_APP_LAYOUT_UI)(MenuLink);
