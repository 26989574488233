import { isEqual } from 'lodash';
import { action, observable, toJS } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { DiscussionSpaceTriggerInfo } from 'app/models';
import DiscussionSpaceWebsocketStateModel, {
  DiscussionSpaceWebsocketState,
} from 'app/models/DiscussionSpaceWebsocketStateModel';
import Store from 'app/stores/Store';

export class DiscussionSpaceWebsocketStateStore extends Store<DiscussionSpaceWebsocketStateModel> {
  @observable token;
  @action setToken = (token: string): void => {
    this.token = token;
  };

  @observable isUpdating = false;
  @action setIsUpdating = (isUpdating: boolean): void => {
    this.isUpdating = isUpdating;
  };

  constructor() {
    super();
    DiscussionSpaceWebsocketStateModel._store = this;
  }

  async updateWebsocketStateForDiscussionSpace(
    discussion_space_id: number,
    state: DiscussionSpaceWebsocketState,
    triggers?: DiscussionSpaceTriggerInfo[]
  ): Promise<DiscussionSpaceWebsocketStateModel> {
    this.setIsUpdating(true);
    try {
      let url = ServerRouteHelper.api.discussionSpace.state(discussion_space_id);

      if (this.token) {
        url = url.withParams({ token: this.token });
      }

      const response = await this.apiService.put(url, { state, triggers });

      if (!response || this.shouldSkipState(response?.data.state, state)) {
        return;
      }

      return DiscussionSpaceWebsocketStateModel.fromJson(response?.data);
    } finally {
      this.setIsUpdating(false);
    }
  }

  // Check if the incoming state should replace the existing one
  shouldSkipState = (
    incomingState: DiscussionSpaceWebsocketState,
    existingState: DiscussionSpaceWebsocketState
  ): boolean => {
    const {
      active_agenda_id: incomingActiveAgendaId,
      active_agenda_started_at: incomingStartedAt,
      ...incomingRest
    } = toJS(incomingState);

    const {
      active_agenda_id: existingActiveAgendaId,
      active_agenda_started_at: existingStartedAt,
      ...existingRest
    } = toJS(existingState);

    // If the rest of the fields remain the same and  the existing
    // timestamp is >= incoming timestamp, then no need to update.
    const isRestEqual = isEqual(incomingRest, existingRest);
    const shouldSkip = existingStartedAt >= incomingStartedAt;

    return isRestEqual && shouldSkip;
  };
}

export default DiscussionSpaceWebsocketStateStore;
