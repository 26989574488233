import React, { Component, ReactElement } from 'react';

import { Menu } from 'antd';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './NavMenuList.module.scss';

import { AppLayoutUiStore, MenuItemUiStore } from 'app/components/features/AppLayout/stores';
import FakeBox from 'app/components/ui/FakeBox';
import { STORE_APP_LAYOUT_UI, STORE_MENU } from 'app/constants';
import { MenuModel } from 'app/models';
import { MenuId, MenuStore } from 'app/stores';

export interface NavMenuListProps {
  menuStore?: MenuStore;
  appLayoutUiStore?: AppLayoutUiStore;
  menu?: MenuModel;
  /**
   * Set this true when the Menu has not yet had a chance to react to a path change
   *
   * Not all menu items cause menu reloads. But when the route has org or team in it, menu items will no longer
   * match the current path until the menu has reloaded (with urls that match the new org/team).
   *
   * This boolean tells NavMenuList, "I know the menu might be stale. If you find *zero* matching items, give me the
   * last matched menu key. If my existing menu has a key that matches that, great, I'll use that to set the
   * selectedKeys, so that the active-styled menu item doesn't "blink" every time the menu has to reload"
   */
  menuIsStaleAfterPathChange: boolean;
}

export class NavMenuList extends Component<NavMenuListProps> {
  protected menuItemUiStore: MenuItemUiStore;

  private lastMatchedKey = '';

  constructor(props: NavMenuListProps) {
    super(props);
    this.menuItemUiStore = new MenuItemUiStore(styles);
  }

  @computed
  get isLoading(): boolean {
    return this.props.menuStore?.isLoading(MenuId.Main);
  }

  handleSidebarNavMenuOffClick = (): void => {
    this.props.appLayoutUiStore.toggleSidebarNavMenu();
  };

  @computed
  get hasNoMenu(): boolean {
    return !this.props.menu || this.props.menu.menu_items.length === 0;
  }

  @computed
  get currentKey(): string {
    const item = this.props.appLayoutUiStore.currentMenuItem();

    if (item) {
      const key = `menu-link-${item?.id}`;
      this.lastMatchedKey = key;
      return key;
    }

    if (!item && this.props.menuIsStaleAfterPathChange) {
      // We found no matching menu items.
      // But `menuIsStaleAfterPathChange` means that the Menu has not loaded since the path changed.
      // ... almost certainly means the "old menu" is still showing.
      // So return the last matched key: if the "old menu" is showing, this allows the active item to persist until
      // the menu reloads -- i.e. the  active tyle won't "blink" while the menu reloads.
      // (After menu reloads, `menuIsStaleAfterPathChange` will be false so we won't get here.)
      return this.lastMatchedKey;
    }

    return '';
  }

  render(): ReactElement {
    if (this.hasNoMenu && this.isLoading) {
      // The very first menu load (hasNoMenu), show a fakebox loading placeholder.
      // For subsequent menu _changes_, we don't want to switch to fakebox. Instead, `isLoading` passed to makeItem
      //  will ensure that menus are unclickable while a new menu is loading.
      return (
        <>
          <FakeBox className={styles.navMenuFakeLoadingItem} />
          <FakeBox className={styles.navMenuFakeLoadingItem} />
          <FakeBox className={styles.navMenuFakeLoadingItem} />
        </>
      );
    }

    if (this.hasNoMenu) {
      return null;
    }

    // Warning: [antd: Menu] `children` will be removed in next major version. Please use `items` instead.
    // we need to rewrite our usage of `Menu` so that we pass items using `items` attribute instead of the current method
    return (
      <Menu
        mode="vertical"
        className={styles.navMenuList}
        triggerSubMenuAction="click"
        onOpenChange={this.handleSidebarNavMenuOffClick}
        selectedKeys={[this.currentKey]}
      >
        {this.props.menu.menu_items.map((item, index) =>
          this.menuItemUiStore.makeItem(item, index, this.isLoading)
        )}
      </Menu>
    );
  }
}

export default inject(STORE_APP_LAYOUT_UI, STORE_MENU)(observer(NavMenuList));
