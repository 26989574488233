import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import ExerciseTypeMetricsStore from 'app/stores/ExerciseTypeMetricsStore';

interface ExerciseTypeClassified {
  align_statement_template_id: number;
  diagnose_percentage: string;
  discuss_percentage: string;
  celebrate_percentage: string;
  text: string;
}

export class ExerciseTypeMetricsModel extends Model {
  public static readonly _store: ExerciseTypeMetricsStore;

  @observable public id: string;
  @observable public exercise_type_classified: Record<number, ExerciseTypeClassified>;

  static fromJson(json: ModelJson): ExerciseTypeMetricsModel {
    return this._fromJson(json) as ExerciseTypeMetricsModel;
  }

  static getOrNew(id): ExerciseTypeMetricsModel {
    return this._getOrNew(id) as ExerciseTypeMetricsModel;
  }

  static get(id): ExerciseTypeMetricsModel {
    return this._get(id) as ExerciseTypeMetricsModel;
  }
}

export default ExerciseTypeMetricsModel;
