import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList, TimezoneModel } from 'app/models';
import Store from 'app/stores/Store';

export class TimezoneStore extends Store<TimezoneModel> {
  @observable timezones = new ModelList<TimezoneModel>(TimezoneModel);

  constructor() {
    super();
    TimezoneModel._store = this;
  }

  getTimezones(): Promise<void> {
    const url = ServerRouteHelper.api.timezone.list();
    return this.timezones.load(url);
  }
}
export default TimezoneStore;
